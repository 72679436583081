/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCompany = /* GraphQL */ `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    name
    country
    inactiveAt
    clients {
      items {
        id
        firstName
        lastName
        email
        inactiveAt
        createdAt
        updatedAt
        companyClientsId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCompanyMutationVariables,
  APITypes.CreateCompanyMutation
>;
export const updateCompany = /* GraphQL */ `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    name
    country
    inactiveAt
    clients {
      items {
        id
        firstName
        lastName
        email
        inactiveAt
        createdAt
        updatedAt
        companyClientsId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyMutationVariables,
  APITypes.UpdateCompanyMutation
>;
export const deleteCompany = /* GraphQL */ `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    name
    country
    inactiveAt
    clients {
      items {
        id
        firstName
        lastName
        email
        inactiveAt
        createdAt
        updatedAt
        companyClientsId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyMutationVariables,
  APITypes.DeleteCompanyMutation
>;
export const createClient = /* GraphQL */ `mutation CreateClient(
  $input: CreateClientInput!
  $condition: ModelClientConditionInput
) {
  createClient(input: $input, condition: $condition) {
    id
    company {
      id
      name
      country
      inactiveAt
      clients {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    firstName
    lastName
    email
    inactiveAt
    employees {
      items {
        id
        clientID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    timelogs {
      items {
        id
        date
        standard
        overtime
        sick
        holiday
        notes
        approved
        onCall
        employeeID
        clientID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    companyClientsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClientMutationVariables,
  APITypes.CreateClientMutation
>;
export const updateClient = /* GraphQL */ `mutation UpdateClient(
  $input: UpdateClientInput!
  $condition: ModelClientConditionInput
) {
  updateClient(input: $input, condition: $condition) {
    id
    company {
      id
      name
      country
      inactiveAt
      clients {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    firstName
    lastName
    email
    inactiveAt
    employees {
      items {
        id
        clientID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    timelogs {
      items {
        id
        date
        standard
        overtime
        sick
        holiday
        notes
        approved
        onCall
        employeeID
        clientID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    companyClientsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientMutationVariables,
  APITypes.UpdateClientMutation
>;
export const deleteClient = /* GraphQL */ `mutation DeleteClient(
  $input: DeleteClientInput!
  $condition: ModelClientConditionInput
) {
  deleteClient(input: $input, condition: $condition) {
    id
    company {
      id
      name
      country
      inactiveAt
      clients {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    firstName
    lastName
    email
    inactiveAt
    employees {
      items {
        id
        clientID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    timelogs {
      items {
        id
        date
        standard
        overtime
        sick
        holiday
        notes
        approved
        onCall
        employeeID
        clientID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    companyClientsId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientMutationVariables,
  APITypes.DeleteClientMutation
>;
export const createEmployee = /* GraphQL */ `mutation CreateEmployee(
  $input: CreateEmployeeInput!
  $condition: ModelEmployeeConditionInput
) {
  createEmployee(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    country
    region
    vacationAllowance
    inactiveAt
    clients {
      items {
        id
        clientID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    timelogs {
      items {
        id
        date
        standard
        overtime
        sick
        holiday
        notes
        approved
        onCall
        employeeID
        clientID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    absences {
      items {
        id
        startDate
        endDate
        amount
        description
        notes
        approvedByClient
        approvalStatus
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmployeeMutationVariables,
  APITypes.CreateEmployeeMutation
>;
export const updateEmployee = /* GraphQL */ `mutation UpdateEmployee(
  $input: UpdateEmployeeInput!
  $condition: ModelEmployeeConditionInput
) {
  updateEmployee(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    country
    region
    vacationAllowance
    inactiveAt
    clients {
      items {
        id
        clientID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    timelogs {
      items {
        id
        date
        standard
        overtime
        sick
        holiday
        notes
        approved
        onCall
        employeeID
        clientID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    absences {
      items {
        id
        startDate
        endDate
        amount
        description
        notes
        approvedByClient
        approvalStatus
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmployeeMutationVariables,
  APITypes.UpdateEmployeeMutation
>;
export const deleteEmployee = /* GraphQL */ `mutation DeleteEmployee(
  $input: DeleteEmployeeInput!
  $condition: ModelEmployeeConditionInput
) {
  deleteEmployee(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    country
    region
    vacationAllowance
    inactiveAt
    clients {
      items {
        id
        clientID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    timelogs {
      items {
        id
        date
        standard
        overtime
        sick
        holiday
        notes
        approved
        onCall
        employeeID
        clientID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    absences {
      items {
        id
        startDate
        endDate
        amount
        description
        notes
        approvedByClient
        approvalStatus
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmployeeMutationVariables,
  APITypes.DeleteEmployeeMutation
>;
export const createTimelog = /* GraphQL */ `mutation CreateTimelog(
  $input: CreateTimelogInput!
  $condition: ModelTimelogConditionInput
) {
  createTimelog(input: $input, condition: $condition) {
    id
    date
    standard
    overtime
    sick
    holiday
    notes
    approved
    onCall
    employeeID
    clientID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTimelogMutationVariables,
  APITypes.CreateTimelogMutation
>;
export const updateTimelog = /* GraphQL */ `mutation UpdateTimelog(
  $input: UpdateTimelogInput!
  $condition: ModelTimelogConditionInput
) {
  updateTimelog(input: $input, condition: $condition) {
    id
    date
    standard
    overtime
    sick
    holiday
    notes
    approved
    onCall
    employeeID
    clientID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTimelogMutationVariables,
  APITypes.UpdateTimelogMutation
>;
export const deleteTimelog = /* GraphQL */ `mutation DeleteTimelog(
  $input: DeleteTimelogInput!
  $condition: ModelTimelogConditionInput
) {
  deleteTimelog(input: $input, condition: $condition) {
    id
    date
    standard
    overtime
    sick
    holiday
    notes
    approved
    onCall
    employeeID
    clientID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTimelogMutationVariables,
  APITypes.DeleteTimelogMutation
>;
export const createAbsence = /* GraphQL */ `mutation CreateAbsence(
  $input: CreateAbsenceInput!
  $condition: ModelAbsenceConditionInput
) {
  createAbsence(input: $input, condition: $condition) {
    id
    startDate
    endDate
    amount
    description
    notes
    approvedByClient
    approvalStatus
    employeeID
    employee {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAbsenceMutationVariables,
  APITypes.CreateAbsenceMutation
>;
export const updateAbsence = /* GraphQL */ `mutation UpdateAbsence(
  $input: UpdateAbsenceInput!
  $condition: ModelAbsenceConditionInput
) {
  updateAbsence(input: $input, condition: $condition) {
    id
    startDate
    endDate
    amount
    description
    notes
    approvedByClient
    approvalStatus
    employeeID
    employee {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAbsenceMutationVariables,
  APITypes.UpdateAbsenceMutation
>;
export const deleteAbsence = /* GraphQL */ `mutation DeleteAbsence(
  $input: DeleteAbsenceInput!
  $condition: ModelAbsenceConditionInput
) {
  deleteAbsence(input: $input, condition: $condition) {
    id
    startDate
    endDate
    amount
    description
    notes
    approvedByClient
    approvalStatus
    employeeID
    employee {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAbsenceMutationVariables,
  APITypes.DeleteAbsenceMutation
>;
export const createClientContracts = /* GraphQL */ `mutation CreateClientContracts(
  $input: CreateClientContractsInput!
  $condition: ModelClientContractsConditionInput
) {
  createClientContracts(input: $input, condition: $condition) {
    id
    clientID
    employeeID
    client {
      id
      company {
        id
        name
        country
        inactiveAt
        createdAt
        updatedAt
        __typename
      }
      firstName
      lastName
      email
      inactiveAt
      employees {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyClientsId
      __typename
    }
    employee {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClientContractsMutationVariables,
  APITypes.CreateClientContractsMutation
>;
export const updateClientContracts = /* GraphQL */ `mutation UpdateClientContracts(
  $input: UpdateClientContractsInput!
  $condition: ModelClientContractsConditionInput
) {
  updateClientContracts(input: $input, condition: $condition) {
    id
    clientID
    employeeID
    client {
      id
      company {
        id
        name
        country
        inactiveAt
        createdAt
        updatedAt
        __typename
      }
      firstName
      lastName
      email
      inactiveAt
      employees {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyClientsId
      __typename
    }
    employee {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientContractsMutationVariables,
  APITypes.UpdateClientContractsMutation
>;
export const deleteClientContracts = /* GraphQL */ `mutation DeleteClientContracts(
  $input: DeleteClientContractsInput!
  $condition: ModelClientContractsConditionInput
) {
  deleteClientContracts(input: $input, condition: $condition) {
    id
    clientID
    employeeID
    client {
      id
      company {
        id
        name
        country
        inactiveAt
        createdAt
        updatedAt
        __typename
      }
      firstName
      lastName
      email
      inactiveAt
      employees {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyClientsId
      __typename
    }
    employee {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientContractsMutationVariables,
  APITypes.DeleteClientContractsMutation
>;
