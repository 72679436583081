import React, { useMemo } from "react";
import { TextField, Autocomplete } from "@mui/material";

type Option = {
  label: string;
  value: string;
  key: string | number;
  group?: string;
  // disabled?: boolean;
};

type SingleProps = {
  onChange: (item: string) => void;
  value: string;
  multiple?: false;
};

type MultiProps = {
  onChange: (item: string[]) => void;
  value: string[];
  multiple: true;
};

type Props = (SingleProps | MultiProps) & {
  label: string;
  options: Option[];
  grouped?: boolean;
  width?: string | number;
};

const findOption = (options: Option[], value: string) =>
  options.find((option) => option.value === value);

export const SearchField = ({
  onChange,
  label,
  options,
  value,
  multiple,
  grouped,
  width,
}: Props) => {
  const selected = useMemo(
    () =>
      (typeof value === "string" ? [value] : value).flatMap(
        (v) => findOption(options, v) ?? []
      ),
    [options, value]
  );

  const group = {
    ...(grouped && { groupBy: ({ group }: Option) => group ?? "" }),
  };

  return multiple ? (
    <Autocomplete
      fullWidth
      size="small"
      onChange={(_, val) => {
        onChange(val.map((v) => (typeof v === "string" ? v : v?.value ?? "")));
      }}
      value={selected}
      options={options}
      {...group}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ bgcolor: "common.white", width: width }}
          variant="outlined"
          margin="dense"
          label={label}
        />
      )}
      multiple
      freeSolo
    />
  ) : (
    <Autocomplete
      size="small"
      onChange={(_, val) => {
        onChange(typeof val === "string" ? val : val?.value ?? "");
      }}
      value={selected[0] || ""}
      options={options}
      {...group}
      renderOption={(props, option) => (
        <li {...props} key={option.key}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ bgcolor: "common.white", width: width }}
          variant="outlined"
          margin="dense"
          label={label}
        />
      )}
      freeSolo
      selectOnFocus
      clearOnBlur
    />
  );
};
