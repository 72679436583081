import React from "react";
import { Controller, Control, Path, FieldValues } from "react-hook-form";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
} from "@mui/material";

type Props<T extends FieldValues> = Omit<CheckboxProps, "name" | "value"> & {
  control: Control<T>;
  name: Path<T>;
  label: string;
};

export const Checkbox = <T extends Record<string, unknown>>({
  control,
  name,
  label,
  ...rest
}: Props<T>) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <FormControlLabel
        control={
          <MuiCheckbox {...field} {...rest} checked={field.value as boolean} />
        }
        label={label}
      />
    )}
  />
);
