import { capitalize } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { gqlQuery, toAWSDate } from "../../lib";
import { Absence, Employee } from "../../API";
import { colors } from "../../lib/styles";

export const useCalendarQuery = ({
  date,
  employeeID,
  page,
}: {
  date: Interval;
  employeeID?: string;
  page: { rowsPerPage: number; current: number };
}) => {
  const dateRange = [
    toAWSDate(new Date(date.start)),
    toAWSDate(new Date(date.end)),
  ];

  const {
    data: absenceData,
    isLoading,
    isFetching,
    isPreviousData,
    error,
  } = useQuery(
    gqlQuery({
      req: "listAbsencesWithEmployees",
      vars: {
        filter: {
          or: [
            { startDate: { between: dateRange } },
            { endDate: { between: dateRange } },
          ],
        },
      },
      key: [toAWSDate(new Date(date.start)) ?? "Invalid date"],
    })
  );

  const { listAbsences } = absenceData?.data ?? {};
  const absences = (listAbsences?.items ?? []) as Absence[];

  const employeeData: Employee[] = (absences ?? []).map(
    (absence) => absence.employee as Employee
  );
  const employees: Employee[] = filterUniqueEmployees(
    employeeData
  ) as Employee[];

  let items: Employee[] =
    employees.map((e) => ({
      ...e,
      absences: {
        items: (absences ?? []).filter((a) => a.employeeID === e.id),
        __typename: "ModelAbsenceConnection",
      },
    })) ?? [];

  if (employeeID) {
    items = items.filter((employee) => employee.id === employeeID);
  }

  const employeesWithAbsences = (items ?? []).map((employee) => {
    const fullName = `${employee?.firstName} ${employee?.lastName}`;
    return {
      id: employee?.id ?? "",
      title: fullName,
      label: fullName,
      events: (employee?.absences?.items as Absence[]).map((a) => ({
        start: new Date(a?.startDate.replace(/-/g, "/") ?? ""),
        end: new Date(a?.endDate.replace(/-/g, "/") ?? ""),
        title: capitalize(a?.approvalStatus ?? ""),
        color: colors[a?.approvalStatus ?? "default"],
      })),
    };
  });

  const total = employeesWithAbsences.length;

  const paginated = employeesWithAbsences.slice(
    page.current * page.rowsPerPage,
    (page.current + 1) * page.rowsPerPage
  );

  return {
    items: paginated,
    total,
    isPending: isLoading || isPreviousData || isFetching,
    error: error as Error,
  };
};

const filterUniqueEmployees = (employees: Employee[]): Employee[] => {
  const uniqueKeys: Set<string> = new Set();
  return employees.filter((obj) => {
    if (!uniqueKeys.has(obj.id)) {
      uniqueKeys.add(obj.id);
      return true;
    }
    return false;
  });
};
