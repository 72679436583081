import React, { FC, useState } from "react";
import { Box } from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";
import { CustomButton, CustomTextField } from "./CustomPicker";

type Props = Omit<MuiDatePickerProps<Interval, Interval>, "renderInput">;

export const MonthPicker: FC<Props> = ({
  label = "Select Month",
  value,
  onChange,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    startOfMonth(new Date(value?.start ?? ""))
  );

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);

    const interval: Interval = {
      start: startOfMonth(date),
      end: endOfMonth(date),
    };

    onChange(interval);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <CustomButton
          aria-label="Previous month"
          variant="outlined"
          onClick={() => handleDateChange(subMonths(selectedDate, 1))}
        >
          <ChevronLeftIcon />
        </CustomButton>
        <MuiDatePicker
          value={selectedDate}
          label={label}
          views={["year", "month"]}
          openTo="month"
          renderInput={(params) => (
            <CustomTextField
              {...params}
              variant="outlined"
              inputProps={{ ...params.inputProps, readOnly: true }}
              InputLabelProps={{ shrink: true }}
              margin="dense"
              size="small"
            />
          )}
          onChange={(e) => handleDateChange(e as Date)}
        />

        <CustomButton
          aria-label="Next month"
          variant="outlined"
          onClick={() => handleDateChange(addMonths(selectedDate, 1))}
        >
          <ChevronRightIcon />
        </CustomButton>
      </Box>
    </LocalizationProvider>
  );
};
