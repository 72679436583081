import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, FormControlLabel, Switch } from "@mui/material";
import Holidays from "date-holidays";
import {
  DefaultModalProps,
  ModalSkeleton,
  SelectField,
  TextField,
  CountryField,
} from "../../../components";
import {
  Employee,
  CreateEmployeeInput,
  ClientContracts,
  Country,
} from "../../../API";
import { toAWSDate } from "../../../lib";
import DateControl from "../../../components/DatePickers/DateControl";

export type FormFields = CreateEmployeeInput;

type Props = DefaultModalProps & {
  title: string;
  onSubmit: ({
    data,
    contracts,
  }: {
    data: FormFields;
    contracts: ClientContracts[];
  }) => void;
  testID: string;
  params: { row?: Employee };
};
export const EmployeeModal: FC<Props> = ({
  title,
  onSubmit,
  testID,
  params: { row },
  ...rest
}) => {
  const hd = new Holidays();

  const initialInactiveAt = row?.inactiveAt ?? null;
  const contracts = row?.clients?.items as ClientContracts[];

  const [saving, setSaving] = useState(false);
  const [isInactive, setIsInactive] = useState<boolean>(!!initialInactiveAt);
  const [regionOptions, setRegionOptions] = useState(
    mapOptions(hd.getStates(row?.country ?? ""))
  );

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: mapDefaultValues(row),
  });
  const handleInactiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsInactive(e.target.checked);
    setValue(
      "inactiveAt",
      e.target.checked ? initialInactiveAt || toAWSDate(new Date()) : null
    );
  };

  const handleCountryChange = (value: Country) => {
    setValue("country", value);
    setValue("region", "");
    setRegionOptions(mapOptions(hd.getStates(value)));
  };

  return (
    <ModalSkeleton
      {...rest}
      title={title}
      isForm
      primaryAction={{
        label: "Save",
        pending: saving,
        onSubmit: handleSubmit((data) => {
          data.vacationAllowance = Number(data.vacationAllowance);
          setSaving(true);
          return onSubmit({ data, contracts });
        }),
        testID: "save",
      }}
      testID={testID}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
          gap: 2,
        }}
      >
        <TextField control={control} name="firstName" label="First name" />
        <TextField control={control} name="lastName" label="Last name" />

        <TextField control={control} name="email" label="Email" type="email" />
        <TextField
          required
          control={control}
          name="vacationAllowance"
          label="Vacation Allowance"
          type="number"
        />

        <CountryField
          control={control}
          name="country"
          label="Country"
          onChange={(value) => handleCountryChange(value)}
        />

        <SelectField
          control={control}
          name="region"
          label="Region"
          options={regionOptions}
          optional={!regionOptions.length}
          variant="outlined"
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControlLabel
            labelPlacement="start"
            label="Inactive"
            sx={{
              m: 0,
            }}
            control={
              <Switch checked={isInactive} onChange={handleInactiveChange} />
            }
          />
          {isInactive && (
            <DateControl
              name="inactiveAt"
              control={control}
              label="Leaving date"
              onChange={(date) => setValue("inactiveAt", toAWSDate(date))}
            />
          )}
        </Box>
      </Box>
    </ModalSkeleton>
  );
};
const mapDefaultValues = (value?: FormFields): FormFields => ({
  id: value?.id,
  firstName: value?.firstName ?? "",
  lastName: value?.lastName ?? "",
  email: value?.email ?? "",
  country: value?.country ?? Country.GB,
  region: value?.region ?? "ENG",
  vacationAllowance: value?.vacationAllowance ?? 0,
  inactiveAt: value?.inactiveAt ?? null,
});

const mapOptions = (values: { [key: string]: string }) => {
  if (values) {
    return Object.keys(values).map((key: string) => ({
      label: values[key],
      value: key,
    }));
  }
  return [];
};
