import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import {
  DefaultModalProps,
  ModalSkeleton,
  TextField,
} from "../../../components";
import { Absence, UpdateAbsenceInput, ApprovalStatus } from "../../../API";
import { User, useUser } from "../../../hooks";

const TEST_ID = "absence-modal";

type FormFields = UpdateAbsenceInput;

type Props = DefaultModalProps & {
  onSubmit: ({ data }: { data: FormFields }) => void;
  testID: string;
  params: {
    row?: Absence;
    status: ApprovalStatus;
  };
};

export const ActionModal: FC<Props> = ({
  onSubmit,
  testID,
  params: { row, status },
  ...rest
}) => {
  const user = useUser() as User;
  const { control, handleSubmit } = useForm({
    defaultValues: mapDefaultValues(row, user, status),
  });
  const [saving, setSaving] = useState(false);
  const statusToTitle =
    status === ApprovalStatus.approved ? "Approve" : "Reject";

  return (
    <ModalSkeleton
      {...rest}
      title={`${statusToTitle} Time Off Request`}
      isForm
      primaryAction={{
        label: "Save",
        pending: saving,
        onSubmit: handleSubmit((data) => {
          setSaving(true);
          const x = data;
          return onSubmit({ data: x });
        }),
        testID: "save-request",
      }}
      testID={testID}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)" },
          alignItems: "center",
          gap: 2,
          mb: 1,
        }}
      >
        <TextField
          control={control}
          name="notes"
          label="Notes"
          multiline
          rows={2}
          data-testid={`${TEST_ID}-notes`}
          optional
          helperText="Use this field to provide additional information about your approval/denial."
        />
      </Box>
    </ModalSkeleton>
  );
};

const mapDefaultValues = (
  value?: Absence,
  user?: User,
  status?: ApprovalStatus
): FormFields => ({
  id: value?.id ?? "",
  startDate: value?.startDate ?? new Date().toISOString(),
  endDate: value?.endDate ?? new Date().toISOString(),
  notes: value?.notes ?? "",
  approvalStatus: status ?? ApprovalStatus.pending,
});
