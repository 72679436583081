import React, { useState } from "react";
import { TablePagination } from "@mui/material";
import { CalendarMonth as CalendarMonthIcon } from "@mui/icons-material";
import { EventManager } from "event-manager";
import { endOfMonth, startOfMonth } from "date-fns";
import { FilterBar, PageTitle } from "../../components";
import { useUser } from "../../hooks";
import { colors } from "../../lib/styles";
import { useCalendarQuery } from "./useCalendarQuery";

const TEST_ID = "calendar";

export const Calendar = () => {
  const user = useUser();
  const [employeeID, setEmployeeID] = useState("");
  const [month, setMonth] = useState<Interval>({
    start: startOfMonth(new Date()),
    end: endOfMonth(new Date()),
  });
  const [page, setPage] = useState({
    current: 0,
    rowsPerPage: 10,
  });

  const { items, total, isPending } = useCalendarQuery({
    date: month,
    employeeID,
    page,
  });

  const handleDateChange = (value: Interval | null) => {
    if (value) {
      setMonth(value);
      setPage({ ...page, current: 0 });
      setEmployeeID("");
    }
  };

  return (
    <>
      <PageTitle
        icon={<CalendarMonthIcon />}
        title="Calendar"
        testID={TEST_ID}
      />

      <FilterBar
        employee={
          user && user?.isAdmin
            ? { value: "", onChange: setEmployeeID }
            : undefined
        }
        dates={{
          value: month,
          onChange: handleDateChange,
          type: "month",
        }}
        testID="calendar"
      />

      <EventManager
        resources={items}
        tableId={1}
        showTooltip
        legendItem={colors}
        flat
        hasWeekends
        loading={isPending}
        multiLine
        date={new Date(month.start)}
        pagination={
          <TablePagination
            component="div"
            count={total}
            page={page.current}
            rowsPerPage={page.rowsPerPage}
            onPageChange={(_event, newPage) =>
              setPage({ ...page, current: Number(newPage) })
            }
            onRowsPerPageChange={(e) =>
              setPage({ ...page, rowsPerPage: Number(e.target.value) })
            }
          />
        }
      />
    </>
  );
};
