import React, { ComponentType } from "react";
import { Button, TextField, styled } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { endOfDay, isSameDay, isWithinInterval, startOfDay } from "date-fns";

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

export const renderPickerDays = ({ start, end }: Interval) => {
  start = startOfDay(start);
  end = endOfDay(end);
  const renderDay = (
    date: Date,
    _selectedDates: Date[] | null[],
    pickersDayProps: PickersDayProps<Date>
  ) => {
    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        key={date.toISOString()}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        dayIsBetween={dayIsBetween}
        disableMargin
      />
    );
  };
  return renderDay;
};

export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.common.white,
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  height: 40,
  minWidth: 30,
  marginTop: 8,
  backgroundColor: theme.palette.common.white,
  borderColor: "rgb(0 0 0 / 23%)",
  "&:hover": {
    borderColor: "rgb(0 0 0 / 87%)",
  },
}));

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    !(prop === "dayIsBetween" || prop === "isFirstDay" || prop === "isLastDay"),
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as ComponentType<CustomPickerDayProps>;
