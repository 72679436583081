import './style.css';
import n, { useEffect as $, useState as D, useMemo as S } from "react";
const _ = (e, t = !0) => {
  const l = [], o = new Date(e);
  for (; o.getMonth() === e.getMonth(); )
    (t || !t && !T(o)) && l.push(new Date(o)), o.setDate(o.getDate() + 1);
  return l;
}, N = (e = /* @__PURE__ */ new Date()) => {
  const t = e.getFullYear(), l = e.getMonth() + 1;
  return /* @__PURE__ */ new Date(`${t}/${l}/1`);
}, k = (e) => {
  const t = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], l = e.getDate(), o = z(l);
  return `${t[e.getDay()]} ${l}${o}`;
}, M = (e) => {
  const t = e.getDate();
  let l = "th";
  t === 1 || t === 21 || t === 31 ? l = "st" : t === 2 || t === 22 ? l = "nd" : (t === 3 || t === 23) && (l = "rd");
  const o = e.toLocaleString("default", { month: "short" });
  return `${t}${l} ${o}`;
}, z = (e) => {
  if (e >= 11 && e <= 13)
    return "th";
  switch (e % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}, O = (e, t) => {
  const l = [], o = new Date(e);
  for (; o <= t; )
    l.push(new Date(o)), o.setDate(o.getDate() + 1);
  return l;
}, H = (e) => {
  const t = new Date(e).toLocaleString("en-GB", {
    month: "long",
    year: "numeric"
  }), [l, o] = t.split(" ");
  return `${l}/${o}`;
}, T = (e) => {
  const t = e.getDay();
  return t === 0 || t === 6;
}, Y = "_eventCell_18n8v_1", F = "_tooltip_18n8v_23", Z = "_fadeIn_18n8v_1", I = {
  eventCell: Y,
  tooltip: F,
  fadeIn: Z,
  "duration-cell": "_duration-cell_18n8v_28"
}, Q = ({ id: e, onClick: t }) => {
  const l = (o) => {
    let d;
    const { textContent: a } = o.currentTarget;
    a && typeof a == "string" && (d = JSON.parse(a.split(`
`)[0])), t && t(d);
  };
  return /* @__PURE__ */ n.createElement("td", { id: e, className: I.eventCell, onClick: l });
}, W = (e, t) => {
  if (e.getElementsByClassName("tooltip").length <= 0) {
    const o = document.createElement("div");
    o.classList.add(I.tooltip), o.innerHTML = `
    <b>${t.label}</b><br>
    ${t.event.title}<br>
    ${M(t.event.start)} ${t.event.end ? ` - ${M(t.event.end)}` : ""}
    `, e.appendChild(o);
  }
}, P = "_legend_190oy_1", G = "_type_190oy_12", X = "_color_190oy_25", p = {
  legend: P,
  type: G,
  color: X
}, J = ({ legendItem: e }) => /* @__PURE__ */ n.createElement("div", { className: p.legend }, Object.keys(e).map((t) => /* @__PURE__ */ n.createElement("div", { key: t, className: p.type }, /* @__PURE__ */ n.createElement("span", null, /* @__PURE__ */ n.createElement(
  "div",
  {
    className: p.color,
    style: { backgroundColor: e[t] }
  }
)), t.charAt(0).toUpperCase() + t.slice(1)))), q = "_timelineActions_lvi6x_1", U = "_btn_lvi6x_15", E = {
  timelineActions: q,
  btn: U
}, K = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Ik01NzUtMjQ2IDM0MS00ODBsMjM0LTIzNCA0NyA0OC0xODYgMTg2IDE4NiAxODYtNDcgNDhaIi8+PC9zdmc+", V = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjAiPjxwYXRoIGQ9Ik01MjQtNDgwIDMzOC02NjZsNDctNDggMjM0IDIzNC0yMzQgMjM0LTQ3LTQ4IDE4Ni0xODZaIi8+PC9zdmc+", ee = ({ monthYear: e, onUpdate: t }) => {
  const l = () => {
    let a;
    e.getMonth() <= 0 ? a = /* @__PURE__ */ new Date(`${e.getFullYear()}/12/1`) : a = /* @__PURE__ */ new Date(`${e.getFullYear()}/${e.getMonth()}/1`), t(a);
  }, o = () => {
    let a;
    e.getMonth() >= 11 ? a = /* @__PURE__ */ new Date(`${e.getFullYear() + 1}/1/1`) : a = /* @__PURE__ */ new Date(
      `${e.getFullYear()}/${e.getMonth() + 2}/1`
    ), t(a);
  }, d = () => {
    t(N());
  };
  return /* @__PURE__ */ n.createElement("div", { className: E.timelineActions }, /* @__PURE__ */ n.createElement("button", { className: E.btn, onClick: l }, /* @__PURE__ */ n.createElement("img", { src: K, alt: "<" })), /* @__PURE__ */ n.createElement("button", { className: E.btn, onClick: d }, "Today"), /* @__PURE__ */ n.createElement("button", { className: E.btn, onClick: o }, /* @__PURE__ */ n.createElement("img", { src: V, alt: "<" })));
}, te = "_rollerContainer_1xzg1_1", ne = "_ldsRoller_1xzg1_8", b = {
  rollerContainer: te,
  ldsRoller: ne
}, le = () => /* @__PURE__ */ n.createElement("div", { className: b.rollerContainer }, /* @__PURE__ */ n.createElement("div", { className: b.ldsRoller }, /* @__PURE__ */ n.createElement("div", null), /* @__PURE__ */ n.createElement("div", null), /* @__PURE__ */ n.createElement("div", null), /* @__PURE__ */ n.createElement("div", null), /* @__PURE__ */ n.createElement("div", null), /* @__PURE__ */ n.createElement("div", null), /* @__PURE__ */ n.createElement("div", null), /* @__PURE__ */ n.createElement("div", null))), oe = "_tableContainer_f5y05_1", ae = "_timelineTable_f5y05_10", h = {
  tableContainer: oe,
  timelineTable: ae
}, se = ({
  title: e,
  monthYear: t,
  daysInMonth: l,
  resources: o,
  hasWeekends: d,
  tableId: a,
  multiLine: c,
  flat: g,
  showTooltip: m,
  onClick: r
}) => {
  const u = me({
    resources: o,
    multiLine: c
  });
  de({
    resources: u,
    monthYear: t,
    key: a,
    flat: g,
    showTooltip: m
  });
  const i = (s) => d ? !0 : !d && !T(s);
  return /* @__PURE__ */ n.createElement("div", { className: h.tableContainer }, /* @__PURE__ */ n.createElement("table", { className: h.timelineTable }, /* @__PURE__ */ n.createElement("thead", null, /* @__PURE__ */ n.createElement("tr", null, /* @__PURE__ */ n.createElement("th", null, e), l.map(
    (s) => i(s) && /* @__PURE__ */ n.createElement("th", { key: s.toDateString() }, k(s))
  ))), /* @__PURE__ */ n.createElement("tbody", null, u.map((s) => /* @__PURE__ */ n.createElement("tr", { key: s.id, id: s.id }, /* @__PURE__ */ n.createElement("td", { id: s.title }, s.title), l.map((f) => i(f) && /* @__PURE__ */ n.createElement(
    Q,
    {
      key: `${f.toDateString()}-${s.id}-${a}`,
      id: `${f.toDateString()}-${s.id}-${a}`,
      resource: s,
      onClick: r
    }
  )))))));
}, ce = "_footer_mal4a_1", ie = {
  footer: ce
}, re = ({ legendItem: e, pagination: t, children: l }) => /* @__PURE__ */ n.createElement("div", { className: ie.footer }, e && /* @__PURE__ */ n.createElement(J, { legendItem: e }), l, t), de = ({
  resources: e,
  monthYear: t,
  key: l,
  flat: o,
  showTooltip: d
}) => {
  $(() => {
    if (o) {
      const a = document.getElementById(`${l} timeline-container`);
      a && (a.style.boxShadow = "none");
    }
    e.forEach((a) => {
      a.events.forEach((c) => {
        O(c.start, c.end ?? c.start).forEach((m, r, u) => {
          const i = document.getElementById(
            `${m.toDateString()}-${a.id}-${l}`
          );
          if (i) {
            i.classList.add("duration-cell"), i.style.backgroundColor = c.color ?? "", i.style.cursor = "pointer", i.style.boxShadow = "0 1px 0 0 rgba(0, 0, 0, 0.15)", r === 0 && (i.style.borderTopLeftRadius = "5px", i.style.borderBottomLeftRadius = "5px"), r === u.length - 1 && (i.style.borderTopRightRadius = "5px", i.style.borderBottomRightRadius = "5px");
            const s = {
              event: c,
              resourceId: a.id.split("-")[0]
            };
            i.textContent = JSON.stringify(s), d && W(i, { label: a.label ?? "", event: c });
          }
        });
      });
    });
  }, [t, e]);
}, me = ({
  resources: e,
  multiLine: t
}) => {
  const [l, o] = D([]);
  return t ? (S(() => {
    const d = e.flatMap((a) => {
      const c = {}, g = [];
      for (const r of a.events)
        r.title in c ? c[r.title].events.push(r) : c[r.title] = { title: r.title, events: [r] };
      let m = 1;
      for (const r in c) {
        const u = `${a.id}-${m}`;
        g.push({
          id: u,
          title: m === 1 ? a.title : "",
          label: a.title,
          events: c[r].events
        }), m++;
      }
      return g;
    });
    o(d);
  }, [e]), l) : e;
}, ue = "_timelineContainer_1eiii_1", ge = "_timelineHeadline_1eiii_16", fe = "_spacer_1eiii_36", Ee = "_noData_1eiii_40", C = {
  timelineContainer: ue,
  timelineHeadline: ge,
  spacer: fe,
  noData: Ee
}, ye = ({
  resources: e,
  tableId: t,
  hasWeekends: l = !1,
  flat: o = !1,
  showTooltip: d = !1,
  multiLine: a = !0,
  title: c = null,
  loading: g = !1,
  headline: m = null,
  pagination: r = null,
  actionsPosition: u = "top",
  noDataText: i = "No data",
  date: s,
  legendItem: f = null,
  onClick: x
}) => {
  const [y, w] = D(N()), [v, A] = D(c), [R, j] = D(_(y));
  $(() => {
    j(_(s, l)), A(H(s));
  }, [l, s]);
  const B = m || /* @__PURE__ */ n.createElement(
    ee,
    {
      monthYear: y,
      onUpdate: (L) => w(new Date(L))
    }
  );
  return /* @__PURE__ */ n.createElement(
    "div",
    {
      className: C.timelineContainer,
      id: `${t} timeline-container`
    },
    g ? /* @__PURE__ */ n.createElement(le, null) : e.length > 0 ? /* @__PURE__ */ n.createElement(
      se,
      {
        resources: e,
        title: v,
        daysInMonth: R,
        monthYear: y,
        hasWeekends: l,
        tableId: t,
        flat: o,
        multiLine: a,
        showTooltip: d,
        onClick: x
      }
    ) : /* @__PURE__ */ n.createElement("div", { className: C.noData }, /* @__PURE__ */ n.createElement("div", null, /* @__PURE__ */ n.createElement("h3", null, v), /* @__PURE__ */ n.createElement("div", null, i))),
    /* @__PURE__ */ n.createElement(re, { legendItem: f, pagination: r }, u === "bottom" && B)
  );
};
export {
  ye as EventManager
};
