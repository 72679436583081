import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CalendarMonth as CalendarIcon,
  EventBusy as AbsenceIcon,
} from "@mui/icons-material";
import { GridValidRowModel } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { PageTitle, DataGrid } from "../../components";
import { useResponsive, useUser } from "../../hooks";
import { UpdateAbsenceInput } from "../../API";
import { useAbsenceLib } from "../../lib";
import { columns, hiddenColumnsMobile } from "./columns";
import { useAbsenceQuery } from "./useAbsenceQuery";
import { useActionModals } from "./Modals";

const TEST_ID = "absences";

export const AbsenceTracker = () => {
  const user = useUser();
  const { isMd } = useResponsive();
  const navigate = useNavigate();
  const { items, isPending, isError, refetch } = useAbsenceQuery();
  const { showActionModal } = useActionModals();
  const { updateAbsenceRecord } = useAbsenceLib();
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string, error = false) => {
    enqueueSnackbar(message, { variant: error ? "error" : "success" });
  };

  const isEditable: boolean = user?.isAdmin ?? false;

  const handleRowUpdate = (row: GridValidRowModel) => {
    const { description, id, endDate, startDate } = row;
    const data = {
      description,
      id,
      endDate,
      startDate,
    } as UpdateAbsenceInput;
    updateAbsenceRecord(data, {
      onSuccess: () => postAction("Time Off request has been updated.", false),
      onError: () =>
        postAction(
          "Error updating Time Off request. Please try again in a few seconds",
          true
        ),
    });
    return { ...data, id: row.id };
  };

  const postAction = (message: string, isError: boolean) => {
    showSnackbar(message, isError);
  };

  return (
    <>
      <PageTitle
        action={{
          label: "View Calendar",
          icon: <CalendarIcon />,
          onClick: () => navigate("/admin/calendar"), // TODO navigate to calendar view (#236)
        }}
        icon={<AbsenceIcon />}
        title="Absence Tracker"
        testID={TEST_ID}
      />
      <DataGrid
        columns={columns({ showActionModal, editable: isEditable })}
        data={items || []}
        emptyState={{
          title: "Time Off Requests",
          description:
            "Time Off requests that are pending will appear here for you to approve.",
          testID: TEST_ID,
        }}
        errorState={{
          isError,
          onClick: refetch,
        }}
        loading={isPending}
        sortModel={[
          {
            field: "startDate",
            sort: "asc",
          },
        ]}
        testID={TEST_ID}
        disableColumnMenu
        hideFooterPagination
        disableSelectionOnClick
        hideColumnSelector
        hideExport
        processRowUpdate={handleRowUpdate}
        columnVisibility={isMd ? hiddenColumnsMobile : {}}
      />
    </>
  );
};
