import React from "react";
import { Box, Drawer } from "@mui/material";
import { UserAvatar } from "../UserAvatar";
import { NavList } from "./NavList";
import { CollapsableNav } from "./CollapsableNav";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  drawerwidth?: number;
};

export const Navigation = ({
  isOpen,
  onClose,
  drawerwidth: drawerWidth = 240,
}: Props) => (
  <Box component="div" data-testid="nav">
    {/* Mobile variant */}
    <Drawer
      variant="temporary"
      open={isOpen}
      onClose={onClose}
      sx={{
        display: { xs: "block", md: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
        },
      }}
    >
      <>
        <Box
          p={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 3,
          }}
        >
          <UserAvatar />
        </Box>
        <NavList onClose={onClose} testID="mob" isOpen={isOpen} />
      </>
    </Drawer>
    {/* Desktop variant */}
    <CollapsableNav onClose={onClose} drawerWidth={drawerWidth} />
  </Box>
);
