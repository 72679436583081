import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format, parseISO } from "date-fns";
import { CreateAbsenceInput, UpdateAbsenceInput, ApprovalStatus } from "../API";
import { ApiActions, gqlMutation, toAWSDate } from ".";

export const useAbsenceLib = () => {
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["getEmployeeWithAbsences"]);
      queryClient.invalidateQueries(["listAbsences"]);
    },
  };

  const { mutateAsync: createAbsence } = useMutation({
    ...gqlMutation({ req: "createAbsence" }),
    ...options,
  });
  const { mutateAsync: updateAbsence } = useMutation({
    ...gqlMutation({ req: "updateAbsence" }),
    ...options,
  });
  const { mutateAsync: deleteAbsence } = useMutation({
    ...gqlMutation({ req: "deleteAbsence" }),
    ...options,
  });

  const createAbsenceRecord = async (
    input: CreateAbsenceInput,
    actions: ApiActions
  ) => {
    createAbsence(
      {
        input: {
          ...input,
          startDate: toAWSDate(new Date(input.startDate)) ?? "",
          endDate: toAWSDate(new Date(input.endDate)) ?? "",
          approvalStatus: ApprovalStatus.pending,
        },
      },
      actions
    );
  };

  const updateAbsenceRecord = async (
    input: UpdateAbsenceInput,
    actions: ApiActions
  ) => {
    updateAbsence(
      {
        input: {
          ...input,
          startDate: input.startDate
            ? format(parseISO(input.startDate), "yyyy-MM-dd")
            : "",
          endDate: input.endDate
            ? format(parseISO(input.endDate), "yyyy-MM-dd")
            : "",
        },
      },
      actions
    );
  };

  const deleteAbsenceRecord = async (id: string, actions: ApiActions) => {
    deleteAbsence({ input: { id } }, actions);
  };

  return {
    createAbsenceRecord,
    updateAbsenceRecord,
    deleteAbsenceRecord,
  };
};
