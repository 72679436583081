import React, { ReactElement } from "react";
import {
  AccessAlarm as AccessAlarmIcon,
  Business as BusinessIcon,
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  Rule as RuleIcon,
  StackedLineChart as ChartIcon,
  AlarmOff as TimeOffIcon,
  EventBusy as AbsenceIcon,
  CalendarMonth as CalendarMonthIcon,
} from "@mui/icons-material";

type Link = {
  heading: string;
  name: string;
  to: string;
  icon: ReactElement;
};

const links: Link[] = [
  {
    heading: "Manage",
    name: "Dashboard",
    to: "",
    icon: <DashboardIcon />,
  },
  {
    heading: "Manage",
    name: "Timesheets",
    to: "timesheets",
    icon: <AccessAlarmIcon />,
  },
  {
    heading: "Manage",
    name: "Time off",
    to: "time-off",
    icon: <TimeOffIcon />,
  },
  {
    heading: "Admin",
    name: "Dashboard",
    to: "admin",
    icon: <DashboardIcon />,
  },
  {
    heading: "Admin",
    name: "Approvals",
    to: "admin/approvals",
    icon: <RuleIcon />,
  },
  {
    heading: "Admin",
    name: "Clients",
    to: "admin/clients",
    icon: <BusinessIcon />,
  },
  {
    heading: "Admin",
    name: "Employees",
    to: "admin/employees",
    icon: <GroupIcon />,
  },
  {
    heading: "Admin",
    name: "Reports",
    to: "admin/reports",
    icon: <ChartIcon />,
  },
  {
    heading: "Admin",
    name: "Absence Tracker",
    to: "admin/absence",
    icon: <AbsenceIcon />,
  },
  {
    heading: "Admin",
    name: "Calendar",
    to: "admin/calendar",
    icon: <CalendarMonthIcon />,
  },
];

export const sortedLinks: { [key: string]: Link[] } = links.reduce(
  (groups, item): { [key: string]: Link[] } =>
    Object.assign(groups, {
      [item.heading]: (groups[item.heading] || []).concat(item),
    }),
  Object.create(null)
);
