import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { AppBar, Box, CssBaseline, Toolbar } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Navigation } from "../Navigation";
import { Desktop } from "../AppBarScreens/Desktop";
import { Mobile } from "../AppBarScreens/Mobile";

export const PageLayout = () => {
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <Box sx={{ display: "flex" }} data-testid="page-layout">
      <CssBaseline />
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          bgcolor: grey[100],
        }}
        data-testid="app-bar"
      >
        <Toolbar>
          <Desktop />
          <Mobile onClick={handleDrawerToggle} />
        </Toolbar>
      </AppBar>

      <Navigation
        isOpen={mobileOpen}
        onClose={handleDrawerToggle}
        drawerwidth={drawerWidth}
      />

      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: { md: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
          p: { xs: 2, sm: 3 },
          pt: { xs: 9, sm: 11 },
        }}
        data-testid="main"
      >
        <Outlet />
      </Box>
    </Box>
  );
};
