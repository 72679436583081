import React from "react";
import { Navigate, Outlet, useLocation, useRoutes } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { PageLayout } from "./components";
import {
  Admin,
  Approvals,
  Clients,
  Employees,
  Login,
  Main,
  NotFound,
  Reports,
  Timesheet,
  TimeOff,
  AbsenceTracker,
  Calendar,
} from "./pages";
import { useUser } from "./hooks";

export const Routing = () => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const location = useLocation();
  const isAdmin = useUser()?.isAdmin;

  return useRoutes([
    {
      path: "/",
      element:
        authStatus !== "authenticated" ? (
          <Navigate to="/login" state={{ from: location }} replace />
        ) : (
          <PageLayout />
        ),
      children: [
        { index: true, element: <Main /> },
        { path: "timesheets", element: <Timesheet /> },
        { path: "time-off", element: <TimeOff /> },
        {
          path: "admin",
          element: isAdmin ? (
            <Outlet />
          ) : (
            <Navigate
              to="/"
              state={{ from: location, error: "unauthorized" }}
              replace
            />
          ),
          children: [
            { index: true, element: <Admin /> },
            { path: "approvals", element: <Approvals /> },
            { path: "clients", element: <Clients /> },
            { path: "employees", element: <Employees /> },
            { path: "reports", element: <Reports /> },
            { path: "absence", element: <AbsenceTracker /> },
            { path: "calendar", element: <Calendar /> },
          ],
        },
      ],
    },
    { path: "login", element: <Login /> },
    { path: "*", element: <NotFound /> },
  ]);
};
