import React from "react";
import { Control, FieldValues, Path } from "react-hook-form";
import { SelectChangeEvent } from "@mui/material";
import { OFFICES } from "../../addresses";
import { Country } from "../../API";
import { SelectField } from "./SelectField";

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: (string | undefined) & Path<T>;
  label: string;
  onChange: (value: Country) => void;
};

export const CountryField = <T extends FieldValues>({
  control,
  name,
  label,
  onChange,
}: Props<T>) => {
  const countryOptions = (Object.keys(OFFICES) ?? []).map((key) => ({
    label: OFFICES[key].country,
    value: key,
  }));

  return (
    <SelectField
      control={control}
      name={name}
      label={label}
      options={countryOptions}
      onChange={(e: SelectChangeEvent<unknown>) =>
        onChange(e.target.value as Country)
      }
      variant="outlined"
    />
  );
};
