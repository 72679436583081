/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  inactiveAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelCountryInput = {
  eq?: Country | null,
  ne?: Country | null,
};

export enum Country {
  GB = "GB",
  CA = "CA",
  PL = "PL",
}


export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type Company = {
  __typename: "Company",
  id: string,
  name: string,
  country?: Country | null,
  inactiveAt?: string | null,
  clients?: ModelClientConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelClientConnection = {
  __typename: "ModelClientConnection",
  items:  Array<Client | null >,
  nextToken?: string | null,
};

export type Client = {
  __typename: "Client",
  id: string,
  company: Company,
  firstName: string,
  lastName: string,
  email: string,
  inactiveAt?: string | null,
  employees?: ModelClientContractsConnection | null,
  timelogs?: ModelTimelogConnection | null,
  createdAt: string,
  updatedAt: string,
  companyClientsId?: string | null,
};

export type ModelClientContractsConnection = {
  __typename: "ModelClientContractsConnection",
  items:  Array<ClientContracts | null >,
  nextToken?: string | null,
};

export type ClientContracts = {
  __typename: "ClientContracts",
  id: string,
  clientID: string,
  employeeID: string,
  client: Client,
  employee: Employee,
  createdAt: string,
  updatedAt: string,
};

export type Employee = {
  __typename: "Employee",
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  country?: Country | null,
  region?: string | null,
  vacationAllowance?: number | null,
  inactiveAt?: string | null,
  clients?: ModelClientContractsConnection | null,
  timelogs?: ModelTimelogConnection | null,
  absences?: ModelAbsenceConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelTimelogConnection = {
  __typename: "ModelTimelogConnection",
  items:  Array<Timelog | null >,
  nextToken?: string | null,
};

export type Timelog = {
  __typename: "Timelog",
  id: string,
  date: string,
  standard?: number | null,
  overtime?: number | null,
  sick?: number | null,
  holiday?: number | null,
  notes?: string | null,
  approved?: boolean | null,
  onCall?: boolean | null,
  employeeID?: string | null,
  clientID?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelAbsenceConnection = {
  __typename: "ModelAbsenceConnection",
  items:  Array<Absence | null >,
  nextToken?: string | null,
};

export type Absence = {
  __typename: "Absence",
  id: string,
  startDate: string,
  endDate: string,
  amount?: number | null,
  description?: string | null,
  notes?: string | null,
  approvedByClient?: boolean | null,
  approvalStatus?: ApprovalStatus | null,
  employeeID: string,
  employee: Employee,
  createdAt: string,
  updatedAt: string,
};

export enum ApprovalStatus {
  pending = "pending",
  approved = "approved",
  rejected = "rejected",
  expired = "expired",
}


export type ModelEmployeeFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  region?: ModelStringInput | null,
  vacationAllowance?: ModelFloatInput | null,
  inactiveAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEmployeeFilterInput | null > | null,
  or?: Array< ModelEmployeeFilterInput | null > | null,
  not?: ModelEmployeeFilterInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelEmployeeConnection = {
  __typename: "ModelEmployeeConnection",
  items:  Array<Employee | null >,
  nextToken?: string | null,
};

export type ModelTimelogFilterInput = {
  id?: ModelIDInput | null,
  date?: ModelStringInput | null,
  standard?: ModelFloatInput | null,
  overtime?: ModelFloatInput | null,
  sick?: ModelFloatInput | null,
  holiday?: ModelFloatInput | null,
  notes?: ModelStringInput | null,
  approved?: ModelBooleanInput | null,
  onCall?: ModelBooleanInput | null,
  employeeID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTimelogFilterInput | null > | null,
  or?: Array< ModelTimelogFilterInput | null > | null,
  not?: ModelTimelogFilterInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  inactiveAt?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
  companyClientsId?: ModelIDInput | null,
};

export type ModelAbsenceFilterInput = {
  id?: ModelIDInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  approvedByClient?: ModelBooleanInput | null,
  approvalStatus?: ModelApprovalStatusInput | null,
  employeeID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAbsenceFilterInput | null > | null,
  or?: Array< ModelAbsenceFilterInput | null > | null,
  not?: ModelAbsenceFilterInput | null,
};

export type ModelApprovalStatusInput = {
  eq?: ApprovalStatus | null,
  ne?: ApprovalStatus | null,
};

export type CreateCompanyInput = {
  id?: string | null,
  name: string,
  country?: Country | null,
  inactiveAt?: string | null,
};

export type ModelCompanyConditionInput = {
  name?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  inactiveAt?: ModelStringInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCompanyInput = {
  id: string,
  name?: string | null,
  country?: Country | null,
  inactiveAt?: string | null,
};

export type DeleteCompanyInput = {
  id: string,
};

export type CreateClientInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  inactiveAt?: string | null,
  companyClientsId?: string | null,
};

export type ModelClientConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  inactiveAt?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  companyClientsId?: ModelIDInput | null,
};

export type UpdateClientInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  inactiveAt?: string | null,
  companyClientsId?: string | null,
};

export type DeleteClientInput = {
  id: string,
};

export type CreateEmployeeInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  country?: Country | null,
  region?: string | null,
  vacationAllowance?: number | null,
  inactiveAt?: string | null,
};

export type ModelEmployeeConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  country?: ModelCountryInput | null,
  region?: ModelStringInput | null,
  vacationAllowance?: ModelFloatInput | null,
  inactiveAt?: ModelStringInput | null,
  and?: Array< ModelEmployeeConditionInput | null > | null,
  or?: Array< ModelEmployeeConditionInput | null > | null,
  not?: ModelEmployeeConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateEmployeeInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  country?: Country | null,
  region?: string | null,
  vacationAllowance?: number | null,
  inactiveAt?: string | null,
};

export type DeleteEmployeeInput = {
  id: string,
};

export type CreateTimelogInput = {
  id?: string | null,
  date: string,
  standard?: number | null,
  overtime?: number | null,
  sick?: number | null,
  holiday?: number | null,
  notes?: string | null,
  approved?: boolean | null,
  onCall?: boolean | null,
  employeeID?: string | null,
  clientID?: string | null,
};

export type ModelTimelogConditionInput = {
  date?: ModelStringInput | null,
  standard?: ModelFloatInput | null,
  overtime?: ModelFloatInput | null,
  sick?: ModelFloatInput | null,
  holiday?: ModelFloatInput | null,
  notes?: ModelStringInput | null,
  approved?: ModelBooleanInput | null,
  onCall?: ModelBooleanInput | null,
  employeeID?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  and?: Array< ModelTimelogConditionInput | null > | null,
  or?: Array< ModelTimelogConditionInput | null > | null,
  not?: ModelTimelogConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateTimelogInput = {
  id: string,
  date?: string | null,
  standard?: number | null,
  overtime?: number | null,
  sick?: number | null,
  holiday?: number | null,
  notes?: string | null,
  approved?: boolean | null,
  onCall?: boolean | null,
  employeeID?: string | null,
  clientID?: string | null,
};

export type DeleteTimelogInput = {
  id: string,
};

export type CreateAbsenceInput = {
  id?: string | null,
  startDate: string,
  endDate: string,
  amount?: number | null,
  description?: string | null,
  notes?: string | null,
  approvedByClient?: boolean | null,
  approvalStatus?: ApprovalStatus | null,
  employeeID: string,
};

export type ModelAbsenceConditionInput = {
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  description?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  approvedByClient?: ModelBooleanInput | null,
  approvalStatus?: ModelApprovalStatusInput | null,
  employeeID?: ModelIDInput | null,
  and?: Array< ModelAbsenceConditionInput | null > | null,
  or?: Array< ModelAbsenceConditionInput | null > | null,
  not?: ModelAbsenceConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAbsenceInput = {
  id: string,
  startDate?: string | null,
  endDate?: string | null,
  amount?: number | null,
  description?: string | null,
  notes?: string | null,
  approvedByClient?: boolean | null,
  approvalStatus?: ApprovalStatus | null,
  employeeID?: string | null,
};

export type DeleteAbsenceInput = {
  id: string,
};

export type CreateClientContractsInput = {
  id?: string | null,
  clientID: string,
  employeeID: string,
};

export type ModelClientContractsConditionInput = {
  clientID?: ModelIDInput | null,
  employeeID?: ModelIDInput | null,
  and?: Array< ModelClientContractsConditionInput | null > | null,
  or?: Array< ModelClientContractsConditionInput | null > | null,
  not?: ModelClientContractsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateClientContractsInput = {
  id: string,
  clientID?: string | null,
  employeeID?: string | null,
};

export type DeleteClientContractsInput = {
  id: string,
};

export type ModelClientContractsFilterInput = {
  id?: ModelIDInput | null,
  clientID?: ModelIDInput | null,
  employeeID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClientContractsFilterInput | null > | null,
  or?: Array< ModelClientContractsFilterInput | null > | null,
  not?: ModelClientContractsFilterInput | null,
};

export type ModelSubscriptionCompanyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  inactiveAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
  companyClientsId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionClientFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  inactiveAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientFilterInput | null > | null,
};

export type ModelSubscriptionEmployeeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  country?: ModelSubscriptionStringInput | null,
  region?: ModelSubscriptionStringInput | null,
  vacationAllowance?: ModelSubscriptionFloatInput | null,
  inactiveAt?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEmployeeFilterInput | null > | null,
  or?: Array< ModelSubscriptionEmployeeFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionTimelogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  standard?: ModelSubscriptionFloatInput | null,
  overtime?: ModelSubscriptionFloatInput | null,
  sick?: ModelSubscriptionFloatInput | null,
  holiday?: ModelSubscriptionFloatInput | null,
  notes?: ModelSubscriptionStringInput | null,
  approved?: ModelSubscriptionBooleanInput | null,
  onCall?: ModelSubscriptionBooleanInput | null,
  clientID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTimelogFilterInput | null > | null,
  or?: Array< ModelSubscriptionTimelogFilterInput | null > | null,
  employeeID?: ModelStringInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionAbsenceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  amount?: ModelSubscriptionFloatInput | null,
  description?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  approvedByClient?: ModelSubscriptionBooleanInput | null,
  approvalStatus?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAbsenceFilterInput | null > | null,
  or?: Array< ModelSubscriptionAbsenceFilterInput | null > | null,
  employeeID?: ModelStringInput | null,
};

export type ModelSubscriptionClientContractsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  clientID?: ModelSubscriptionIDInput | null,
  employeeID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionClientContractsFilterInput | null > | null,
  or?: Array< ModelSubscriptionClientContractsFilterInput | null > | null,
};

export type ListCompaniesWithEmployeesQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompaniesWithEmployeesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      name: string,
      inactiveAt?: string | null,
      country?: Country | null,
      clients?:  {
        __typename: "ModelClientConnection",
        items:  Array< {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          inactiveAt?: string | null,
          employees?:  {
            __typename: "ModelClientContractsConnection",
            items:  Array< {
              __typename: "ClientContracts",
              id: string,
              clientID: string,
              employeeID: string,
              employee:  {
                __typename: "Employee",
                id: string,
                firstName: string,
                lastName: string,
                email: string,
                country?: Country | null,
                region?: string | null,
                vacationAllowance?: number | null,
              },
            } | null >,
            nextToken?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEmployeesWithCompaniesQueryVariables = {
  filter?: ModelEmployeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmployeesWithCompaniesQuery = {
  listEmployees?:  {
    __typename: "ModelEmployeeConnection",
    items:  Array< {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        items:  Array< {
          __typename: "ClientContracts",
          id: string,
          clientID: string,
          employeeID: string,
          client:  {
            __typename: "Client",
            id: string,
            firstName: string,
            lastName: string,
            companyClientsId?: string | null,
            company:  {
              __typename: "Company",
              id: string,
              name: string,
              createdAt: string,
              updatedAt: string,
              country?: Country | null,
            },
            email: string,
            createdAt: string,
            updatedAt: string,
          },
          employee:  {
            __typename: "Employee",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            country?: Country | null,
            region?: string | null,
            vacationAllowance?: number | null,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListEmployeesWithTimelogsQueryVariables = {
  employeeID?: string | null,
  limit?: number | null,
  nextToken?: string | null,
  dateRange?: Array< string | null > | null,
  logFilter?: ModelTimelogFilterInput | null,
  nextTokenLogs?: string | null,
};

export type ListEmployeesWithTimelogsQuery = {
  listEmployees?:  {
    __typename: "ModelEmployeeConnection",
    items:  Array< {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        items:  Array< {
          __typename: "ClientContracts",
          id: string,
          clientID: string,
          employeeID: string,
          client:  {
            __typename: "Client",
            id: string,
            firstName: string,
            lastName: string,
            companyClientsId?: string | null,
            company:  {
              __typename: "Company",
              id: string,
              name: string,
              createdAt: string,
              updatedAt: string,
              country?: Country | null,
            },
            email: string,
            createdAt: string,
            updatedAt: string,
          },
          employee:  {
            __typename: "Employee",
            id: string,
            firstName: string,
            lastName: string,
            email: string,
            country?: Country | null,
            region?: string | null,
            vacationAllowance?: number | null,
            createdAt: string,
            updatedAt: string,
          },
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        items:  Array< {
          __typename: "Timelog",
          id: string,
          approved?: boolean | null,
          onCall?: boolean | null,
          date: string,
          standard?: number | null,
          overtime?: number | null,
          sick?: number | null,
          holiday?: number | null,
          notes?: string | null,
          clientID?: string | null,
          employeeID?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEmployeeWithCompanyQueryVariables = {
  id: string,
  dateRange?: Array< string | null > | null,
  filter?: ModelTimelogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetEmployeeWithCompanyQuery = {
  getEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    clients?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          companyClientsId?: string | null,
          company:  {
            __typename: "Company",
            id: string,
            name: string,
            createdAt: string,
            updatedAt: string,
            country?: Country | null,
          },
          email: string,
          createdAt: string,
          updatedAt: string,
        },
        employeeID: string,
        employee:  {
          __typename: "Employee",
          id: string,
          firstName: string,
          lastName: string,
          email: string,
          country?: Country | null,
          region?: string | null,
          vacationAllowance?: number | null,
          createdAt: string,
          updatedAt: string,
        },
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        approved?: boolean | null,
        onCall?: boolean | null,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClientsWithTimelogsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  employeeID?: string | null,
  dateRange?: Array< string | null > | null,
};

export type ListClientsWithTimelogsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      company:  {
        __typename: "Company",
        name: string,
      },
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        items:  Array< {
          __typename: "Timelog",
          id: string,
          approved?: boolean | null,
          onCall?: boolean | null,
          date: string,
          standard?: number | null,
          overtime?: number | null,
          sick?: number | null,
          holiday?: number | null,
          notes?: string | null,
          employeeID?: string | null,
          clientID?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
  } | null,
};

export type GetEmployeeWithAbsencesQueryVariables = {
  id: string,
  startDate: Array< string | null >,
};

export type GetEmployeeWithAbsencesQuery = {
  getEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    inactiveAt?: string | null,
    absences?:  {
      __typename: "ModelAbsenceConnection",
      items:  Array< {
        __typename: "Absence",
        id: string,
        startDate: string,
        endDate: string,
        amount?: number | null,
        description?: string | null,
        approvalStatus?: ApprovalStatus | null,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
        approvedByClient?: boolean | null,
        notes?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAbsencesWithEmployeesQueryVariables = {
  filter?: ModelAbsenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAbsencesWithEmployeesQuery = {
  listAbsences?:  {
    __typename: "ModelAbsenceConnection",
    items:  Array< {
      __typename: "Absence",
      id: string,
      description?: string | null,
      createdAt: string,
      approvalStatus?: ApprovalStatus | null,
      amount?: number | null,
      endDate: string,
      employeeID: string,
      startDate: string,
      updatedAt: string,
      employee:  {
        __typename: "Employee",
        country?: Country | null,
        createdAt: string,
        email: string,
        firstName: string,
        id: string,
        inactiveAt?: string | null,
        lastName: string,
        region?: string | null,
        updatedAt: string,
        vacationAllowance?: number | null,
      },
    } | null >,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    country?: Country | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
        companyClientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    country?: Country | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
        companyClientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    country?: Country | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
        companyClientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient?:  {
    __typename: "Client",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      name: string,
      country?: Country | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    firstName: string,
    lastName: string,
    email: string,
    inactiveAt?: string | null,
    employees?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyClientsId?: string | null,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient?:  {
    __typename: "Client",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      name: string,
      country?: Country | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    firstName: string,
    lastName: string,
    email: string,
    inactiveAt?: string | null,
    employees?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyClientsId?: string | null,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient?:  {
    __typename: "Client",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      name: string,
      country?: Country | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    firstName: string,
    lastName: string,
    email: string,
    inactiveAt?: string | null,
    employees?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyClientsId?: string | null,
  } | null,
};

export type CreateEmployeeMutationVariables = {
  input: CreateEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type CreateEmployeeMutation = {
  createEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    absences?:  {
      __typename: "ModelAbsenceConnection",
      items:  Array< {
        __typename: "Absence",
        id: string,
        startDate: string,
        endDate: string,
        amount?: number | null,
        description?: string | null,
        notes?: string | null,
        approvedByClient?: boolean | null,
        approvalStatus?: ApprovalStatus | null,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateEmployeeMutationVariables = {
  input: UpdateEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type UpdateEmployeeMutation = {
  updateEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    absences?:  {
      __typename: "ModelAbsenceConnection",
      items:  Array< {
        __typename: "Absence",
        id: string,
        startDate: string,
        endDate: string,
        amount?: number | null,
        description?: string | null,
        notes?: string | null,
        approvedByClient?: boolean | null,
        approvalStatus?: ApprovalStatus | null,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteEmployeeMutationVariables = {
  input: DeleteEmployeeInput,
  condition?: ModelEmployeeConditionInput | null,
};

export type DeleteEmployeeMutation = {
  deleteEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    absences?:  {
      __typename: "ModelAbsenceConnection",
      items:  Array< {
        __typename: "Absence",
        id: string,
        startDate: string,
        endDate: string,
        amount?: number | null,
        description?: string | null,
        notes?: string | null,
        approvedByClient?: boolean | null,
        approvalStatus?: ApprovalStatus | null,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTimelogMutationVariables = {
  input: CreateTimelogInput,
  condition?: ModelTimelogConditionInput | null,
};

export type CreateTimelogMutation = {
  createTimelog?:  {
    __typename: "Timelog",
    id: string,
    date: string,
    standard?: number | null,
    overtime?: number | null,
    sick?: number | null,
    holiday?: number | null,
    notes?: string | null,
    approved?: boolean | null,
    onCall?: boolean | null,
    employeeID?: string | null,
    clientID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTimelogMutationVariables = {
  input: UpdateTimelogInput,
  condition?: ModelTimelogConditionInput | null,
};

export type UpdateTimelogMutation = {
  updateTimelog?:  {
    __typename: "Timelog",
    id: string,
    date: string,
    standard?: number | null,
    overtime?: number | null,
    sick?: number | null,
    holiday?: number | null,
    notes?: string | null,
    approved?: boolean | null,
    onCall?: boolean | null,
    employeeID?: string | null,
    clientID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTimelogMutationVariables = {
  input: DeleteTimelogInput,
  condition?: ModelTimelogConditionInput | null,
};

export type DeleteTimelogMutation = {
  deleteTimelog?:  {
    __typename: "Timelog",
    id: string,
    date: string,
    standard?: number | null,
    overtime?: number | null,
    sick?: number | null,
    holiday?: number | null,
    notes?: string | null,
    approved?: boolean | null,
    onCall?: boolean | null,
    employeeID?: string | null,
    clientID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAbsenceMutationVariables = {
  input: CreateAbsenceInput,
  condition?: ModelAbsenceConditionInput | null,
};

export type CreateAbsenceMutation = {
  createAbsence?:  {
    __typename: "Absence",
    id: string,
    startDate: string,
    endDate: string,
    amount?: number | null,
    description?: string | null,
    notes?: string | null,
    approvedByClient?: boolean | null,
    approvalStatus?: ApprovalStatus | null,
    employeeID: string,
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAbsenceMutationVariables = {
  input: UpdateAbsenceInput,
  condition?: ModelAbsenceConditionInput | null,
};

export type UpdateAbsenceMutation = {
  updateAbsence?:  {
    __typename: "Absence",
    id: string,
    startDate: string,
    endDate: string,
    amount?: number | null,
    description?: string | null,
    notes?: string | null,
    approvedByClient?: boolean | null,
    approvalStatus?: ApprovalStatus | null,
    employeeID: string,
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAbsenceMutationVariables = {
  input: DeleteAbsenceInput,
  condition?: ModelAbsenceConditionInput | null,
};

export type DeleteAbsenceMutation = {
  deleteAbsence?:  {
    __typename: "Absence",
    id: string,
    startDate: string,
    endDate: string,
    amount?: number | null,
    description?: string | null,
    notes?: string | null,
    approvedByClient?: boolean | null,
    approvalStatus?: ApprovalStatus | null,
    employeeID: string,
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClientContractsMutationVariables = {
  input: CreateClientContractsInput,
  condition?: ModelClientContractsConditionInput | null,
};

export type CreateClientContractsMutation = {
  createClientContracts?:  {
    __typename: "ClientContracts",
    id: string,
    clientID: string,
    employeeID: string,
    client:  {
      __typename: "Client",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        name: string,
        country?: Country | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      employees?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyClientsId?: string | null,
    },
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClientContractsMutationVariables = {
  input: UpdateClientContractsInput,
  condition?: ModelClientContractsConditionInput | null,
};

export type UpdateClientContractsMutation = {
  updateClientContracts?:  {
    __typename: "ClientContracts",
    id: string,
    clientID: string,
    employeeID: string,
    client:  {
      __typename: "Client",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        name: string,
        country?: Country | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      employees?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyClientsId?: string | null,
    },
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClientContractsMutationVariables = {
  input: DeleteClientContractsInput,
  condition?: ModelClientContractsConditionInput | null,
};

export type DeleteClientContractsMutation = {
  deleteClientContracts?:  {
    __typename: "ClientContracts",
    id: string,
    clientID: string,
    employeeID: string,
    client:  {
      __typename: "Client",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        name: string,
        country?: Country | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      employees?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyClientsId?: string | null,
    },
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    country?: Country | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
        companyClientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCompaniesQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      name: string,
      country?: Country | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient?:  {
    __typename: "Client",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      name: string,
      country?: Country | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    firstName: string,
    lastName: string,
    email: string,
    inactiveAt?: string | null,
    employees?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyClientsId?: string | null,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients?:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        name: string,
        country?: Country | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      employees?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyClientsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEmployeeQueryVariables = {
  id: string,
};

export type GetEmployeeQuery = {
  getEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    absences?:  {
      __typename: "ModelAbsenceConnection",
      items:  Array< {
        __typename: "Absence",
        id: string,
        startDate: string,
        endDate: string,
        amount?: number | null,
        description?: string | null,
        notes?: string | null,
        approvedByClient?: boolean | null,
        approvalStatus?: ApprovalStatus | null,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListEmployeesQueryVariables = {
  filter?: ModelEmployeeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEmployeesQuery = {
  listEmployees?:  {
    __typename: "ModelEmployeeConnection",
    items:  Array< {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTimelogQueryVariables = {
  id: string,
};

export type GetTimelogQuery = {
  getTimelog?:  {
    __typename: "Timelog",
    id: string,
    date: string,
    standard?: number | null,
    overtime?: number | null,
    sick?: number | null,
    holiday?: number | null,
    notes?: string | null,
    approved?: boolean | null,
    onCall?: boolean | null,
    employeeID?: string | null,
    clientID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTimelogsQueryVariables = {
  filter?: ModelTimelogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTimelogsQuery = {
  listTimelogs?:  {
    __typename: "ModelTimelogConnection",
    items:  Array< {
      __typename: "Timelog",
      id: string,
      date: string,
      standard?: number | null,
      overtime?: number | null,
      sick?: number | null,
      holiday?: number | null,
      notes?: string | null,
      approved?: boolean | null,
      onCall?: boolean | null,
      employeeID?: string | null,
      clientID?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAbsenceQueryVariables = {
  id: string,
};

export type GetAbsenceQuery = {
  getAbsence?:  {
    __typename: "Absence",
    id: string,
    startDate: string,
    endDate: string,
    amount?: number | null,
    description?: string | null,
    notes?: string | null,
    approvedByClient?: boolean | null,
    approvalStatus?: ApprovalStatus | null,
    employeeID: string,
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAbsencesQueryVariables = {
  filter?: ModelAbsenceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAbsencesQuery = {
  listAbsences?:  {
    __typename: "ModelAbsenceConnection",
    items:  Array< {
      __typename: "Absence",
      id: string,
      startDate: string,
      endDate: string,
      amount?: number | null,
      description?: string | null,
      notes?: string | null,
      approvedByClient?: boolean | null,
      approvalStatus?: ApprovalStatus | null,
      employeeID: string,
      employee:  {
        __typename: "Employee",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        country?: Country | null,
        region?: string | null,
        vacationAllowance?: number | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClientContractsQueryVariables = {
  id: string,
};

export type GetClientContractsQuery = {
  getClientContracts?:  {
    __typename: "ClientContracts",
    id: string,
    clientID: string,
    employeeID: string,
    client:  {
      __typename: "Client",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        name: string,
        country?: Country | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      employees?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyClientsId?: string | null,
    },
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClientContractsQueryVariables = {
  filter?: ModelClientContractsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientContractsQuery = {
  listClientContracts?:  {
    __typename: "ModelClientContractsConnection",
    items:  Array< {
      __typename: "ClientContracts",
      id: string,
      clientID: string,
      employeeID: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
        companyClientsId?: string | null,
      },
      employee:  {
        __typename: "Employee",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        country?: Country | null,
        region?: string | null,
        vacationAllowance?: number | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    country?: Country | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
        companyClientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    country?: Country | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
        companyClientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    country?: Country | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientConnection",
      items:  Array< {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
        companyClientsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnCreateClientSubscription = {
  onCreateClient?:  {
    __typename: "Client",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      name: string,
      country?: Country | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    firstName: string,
    lastName: string,
    email: string,
    inactiveAt?: string | null,
    employees?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyClientsId?: string | null,
  } | null,
};

export type OnUpdateClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnUpdateClientSubscription = {
  onUpdateClient?:  {
    __typename: "Client",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      name: string,
      country?: Country | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    firstName: string,
    lastName: string,
    email: string,
    inactiveAt?: string | null,
    employees?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyClientsId?: string | null,
  } | null,
};

export type OnDeleteClientSubscriptionVariables = {
  filter?: ModelSubscriptionClientFilterInput | null,
};

export type OnDeleteClientSubscription = {
  onDeleteClient?:  {
    __typename: "Client",
    id: string,
    company:  {
      __typename: "Company",
      id: string,
      name: string,
      country?: Country | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    firstName: string,
    lastName: string,
    email: string,
    inactiveAt?: string | null,
    employees?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    companyClientsId?: string | null,
  } | null,
};

export type OnCreateEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
};

export type OnCreateEmployeeSubscription = {
  onCreateEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    absences?:  {
      __typename: "ModelAbsenceConnection",
      items:  Array< {
        __typename: "Absence",
        id: string,
        startDate: string,
        endDate: string,
        amount?: number | null,
        description?: string | null,
        notes?: string | null,
        approvedByClient?: boolean | null,
        approvalStatus?: ApprovalStatus | null,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
};

export type OnUpdateEmployeeSubscription = {
  onUpdateEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    absences?:  {
      __typename: "ModelAbsenceConnection",
      items:  Array< {
        __typename: "Absence",
        id: string,
        startDate: string,
        endDate: string,
        amount?: number | null,
        description?: string | null,
        notes?: string | null,
        approvedByClient?: boolean | null,
        approvalStatus?: ApprovalStatus | null,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteEmployeeSubscriptionVariables = {
  filter?: ModelSubscriptionEmployeeFilterInput | null,
};

export type OnDeleteEmployeeSubscription = {
  onDeleteEmployee?:  {
    __typename: "Employee",
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    country?: Country | null,
    region?: string | null,
    vacationAllowance?: number | null,
    inactiveAt?: string | null,
    clients?:  {
      __typename: "ModelClientContractsConnection",
      items:  Array< {
        __typename: "ClientContracts",
        id: string,
        clientID: string,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    timelogs?:  {
      __typename: "ModelTimelogConnection",
      items:  Array< {
        __typename: "Timelog",
        id: string,
        date: string,
        standard?: number | null,
        overtime?: number | null,
        sick?: number | null,
        holiday?: number | null,
        notes?: string | null,
        approved?: boolean | null,
        onCall?: boolean | null,
        employeeID?: string | null,
        clientID?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    absences?:  {
      __typename: "ModelAbsenceConnection",
      items:  Array< {
        __typename: "Absence",
        id: string,
        startDate: string,
        endDate: string,
        amount?: number | null,
        description?: string | null,
        notes?: string | null,
        approvedByClient?: boolean | null,
        approvalStatus?: ApprovalStatus | null,
        employeeID: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTimelogSubscriptionVariables = {
  filter?: ModelSubscriptionTimelogFilterInput | null,
  employeeID?: string | null,
};

export type OnCreateTimelogSubscription = {
  onCreateTimelog?:  {
    __typename: "Timelog",
    id: string,
    date: string,
    standard?: number | null,
    overtime?: number | null,
    sick?: number | null,
    holiday?: number | null,
    notes?: string | null,
    approved?: boolean | null,
    onCall?: boolean | null,
    employeeID?: string | null,
    clientID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTimelogSubscriptionVariables = {
  filter?: ModelSubscriptionTimelogFilterInput | null,
  employeeID?: string | null,
};

export type OnUpdateTimelogSubscription = {
  onUpdateTimelog?:  {
    __typename: "Timelog",
    id: string,
    date: string,
    standard?: number | null,
    overtime?: number | null,
    sick?: number | null,
    holiday?: number | null,
    notes?: string | null,
    approved?: boolean | null,
    onCall?: boolean | null,
    employeeID?: string | null,
    clientID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTimelogSubscriptionVariables = {
  filter?: ModelSubscriptionTimelogFilterInput | null,
  employeeID?: string | null,
};

export type OnDeleteTimelogSubscription = {
  onDeleteTimelog?:  {
    __typename: "Timelog",
    id: string,
    date: string,
    standard?: number | null,
    overtime?: number | null,
    sick?: number | null,
    holiday?: number | null,
    notes?: string | null,
    approved?: boolean | null,
    onCall?: boolean | null,
    employeeID?: string | null,
    clientID?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAbsenceSubscriptionVariables = {
  filter?: ModelSubscriptionAbsenceFilterInput | null,
  employeeID?: string | null,
};

export type OnCreateAbsenceSubscription = {
  onCreateAbsence?:  {
    __typename: "Absence",
    id: string,
    startDate: string,
    endDate: string,
    amount?: number | null,
    description?: string | null,
    notes?: string | null,
    approvedByClient?: boolean | null,
    approvalStatus?: ApprovalStatus | null,
    employeeID: string,
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAbsenceSubscriptionVariables = {
  filter?: ModelSubscriptionAbsenceFilterInput | null,
  employeeID?: string | null,
};

export type OnUpdateAbsenceSubscription = {
  onUpdateAbsence?:  {
    __typename: "Absence",
    id: string,
    startDate: string,
    endDate: string,
    amount?: number | null,
    description?: string | null,
    notes?: string | null,
    approvedByClient?: boolean | null,
    approvalStatus?: ApprovalStatus | null,
    employeeID: string,
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAbsenceSubscriptionVariables = {
  filter?: ModelSubscriptionAbsenceFilterInput | null,
  employeeID?: string | null,
};

export type OnDeleteAbsenceSubscription = {
  onDeleteAbsence?:  {
    __typename: "Absence",
    id: string,
    startDate: string,
    endDate: string,
    amount?: number | null,
    description?: string | null,
    notes?: string | null,
    approvedByClient?: boolean | null,
    approvalStatus?: ApprovalStatus | null,
    employeeID: string,
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClientContractsSubscriptionVariables = {
  filter?: ModelSubscriptionClientContractsFilterInput | null,
};

export type OnCreateClientContractsSubscription = {
  onCreateClientContracts?:  {
    __typename: "ClientContracts",
    id: string,
    clientID: string,
    employeeID: string,
    client:  {
      __typename: "Client",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        name: string,
        country?: Country | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      employees?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyClientsId?: string | null,
    },
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClientContractsSubscriptionVariables = {
  filter?: ModelSubscriptionClientContractsFilterInput | null,
};

export type OnUpdateClientContractsSubscription = {
  onUpdateClientContracts?:  {
    __typename: "ClientContracts",
    id: string,
    clientID: string,
    employeeID: string,
    client:  {
      __typename: "Client",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        name: string,
        country?: Country | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      employees?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyClientsId?: string | null,
    },
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClientContractsSubscriptionVariables = {
  filter?: ModelSubscriptionClientContractsFilterInput | null,
};

export type OnDeleteClientContractsSubscription = {
  onDeleteClientContracts?:  {
    __typename: "ClientContracts",
    id: string,
    clientID: string,
    employeeID: string,
    client:  {
      __typename: "Client",
      id: string,
      company:  {
        __typename: "Company",
        id: string,
        name: string,
        country?: Country | null,
        inactiveAt?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      firstName: string,
      lastName: string,
      email: string,
      inactiveAt?: string | null,
      employees?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      companyClientsId?: string | null,
    },
    employee:  {
      __typename: "Employee",
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      country?: Country | null,
      region?: string | null,
      vacationAllowance?: number | null,
      inactiveAt?: string | null,
      clients?:  {
        __typename: "ModelClientContractsConnection",
        nextToken?: string | null,
      } | null,
      timelogs?:  {
        __typename: "ModelTimelogConnection",
        nextToken?: string | null,
      } | null,
      absences?:  {
        __typename: "ModelAbsenceConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
