import React, { FC } from "react";
import { Box, Button } from "@mui/material";

type Props = {
  onApprove: () => void;
  onReject?: () => void;
  disable?: {
    reject: boolean;
    approve: boolean;
  };
};

export const ApprovalCell: FC<Props> = ({ onApprove, onReject, disable }) => (
  <Box sx={{ display: "flex", gap: 1 }}>
    {onReject && (
      <Button
        variant="contained"
        size="small"
        color="error"
        disableElevation
        disabled={disable?.reject ?? false}
        onClick={onReject}
      >
        Reject
      </Button>
    )}

    <Button
      variant="contained"
      size="small"
      color="success"
      disableElevation
      onClick={onApprove}
      disabled={disable?.approve ?? false}
    >
      Approve
    </Button>
  </Box>
);
