import React, { FC, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Box, IconButton, List, ListItem, Tooltip } from "@mui/material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  Unarchive as UnarchiveIcon,
} from "@mui/icons-material";
import {
  DefaultModalProps,
  ModalSkeleton,
  PrimaryButton,
  TextField,
  CountryField,
} from "../../../components";
import {
  Client,
  ClientContracts,
  Company,
  Country,
  CreateCompanyInput,
} from "../../../API";
import { ModalCreateClientInput } from "..";
import { toAWSDate } from "../../../lib";

type FormFields = CreateCompanyInput & {
  clients: ModalCreateClientInput[];
};

type RemoveClients = {
  removeClients: {
    id: string;
    employees: {
      items: { id: string }[];
    };
  }[];
};

type Props = DefaultModalProps & {
  title: string;
  onSubmit: ({
    data,
  }: {
    data: FormFields & { removeClients: Client[] };
  }) => void;
  testID: string;
  params: { row?: Company };
};

export const ClientModal: FC<Props> = ({
  title,
  onSubmit,
  testID,
  params: { row },
  ...rest
}) => {
  const [saving, setSaving] = useState(false);
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: mapDefaultValues(row),
  });
  const { fields, append, update } = useFieldArray({
    control,
    name: "clients",
  });

  return (
    <ModalSkeleton
      {...rest}
      title={title}
      isForm
      primaryAction={{
        label: "Save",
        pending: saving,
        onSubmit: handleSubmit((data) => {
          setSaving(true);
          return onSubmit({
            data: {
              ...data,
              removeClients: data.removeClients as Client[],
            },
          });
        }),
        testID: "save",
      }}
      testID={testID}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
          alignItems: "center",
          gap: 2,
          mb: 1,
        }}
      >
        <TextField control={control} name="name" label="Company" />
        <PrimaryButton
          onClick={() => append({ firstName: "", lastName: "", email: "" })}
          size="medium"
          startIcon={<AddIcon />}
          data-testid={"client-modal-add-contact-button"}
          sx={{ height: 0.75, mt: 0.5 }}
        >
          Add Contact
        </PrimaryButton>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
          alignItems: "center",
          gap: 2,
          mb: 1,
        }}
      >
        <CountryField
          control={control}
          name="country"
          label="Country"
          onChange={(value: Country) => setValue("country", value)}
        />
      </Box>
      <List>
        {fields.map((field, index) => (
          <ListItem key={field.id} disablePadding divider>
            <Tooltip title={!field.inactiveAt ? "Archive" : "Unarchive"}>
              <IconButton
                onClick={() => {
                  update(index, {
                    ...field,
                    inactiveAt: !field.inactiveAt
                      ? toAWSDate(new Date())
                      : null,
                  });
                }}
              >
                {!field.inactiveAt ? <ArchiveIcon /> : <UnarchiveIcon />}
              </IconButton>
            </Tooltip>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                },
                gap: 1,
                flexGrow: 1,
                mt: 1,
                mb: 1,
              }}
            >
              <TextField
                name={`clients.${index}.firstName`}
                control={control}
                label={"First Name"}
                type="firstName"
                disabled={!!field.inactiveAt}
              />
              <TextField
                name={`clients.${index}.lastName`}
                control={control}
                label={"Last Name"}
                type="lastName"
                disabled={!!field.inactiveAt}
              />
              <TextField
                name={`clients.${index}.email`}
                control={control}
                label="Email"
                type="email"
                disabled={!!field.inactiveAt}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </ModalSkeleton>
  );
};

const mapDefaultValues = (value?: Company): FormFields & RemoveClients => ({
  id: value?.id,
  name: value?.name ?? "",
  country: value?.country || Country.GB,
  clients:
    value?.clients?.items.map((c) => ({
      clientID: c?.id ?? "",
      firstName: c?.firstName ?? "",
      lastName: c?.lastName ?? "",
      email: c?.email ?? "",
      inactiveAt: c?.inactiveAt ?? null,
      employees:
        c?.employees?.items.filter((i): i is ClientContracts => i !== null) ??
        [],
    })) ?? [],
  removeClients: [],
});
