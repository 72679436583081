import { ComponentProps } from "react";
import { useSnackbar } from "notistack";
import { useModal } from "../../../components";
import { Absence, ApprovalStatus } from "../../../API";
import { useAbsenceLib } from "../../../lib";
import { ActionModal } from "./ActionModal";

export const useActionModals = () => {
  const { updateAbsenceRecord } = useAbsenceLib();
  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = (message: string, error = false) => {
    enqueueSnackbar(message, { variant: error ? "error" : "success" });
  };

  const postAction = (
    message: string,
    isError: boolean,
    callback: () => void
  ) => {
    showSnackbar(message, isError);
    callback();
  };

  const { showModal: showActionModal, hideModal } = useModal<
    ComponentProps<typeof ActionModal>,
    { row: Absence; status: ApprovalStatus }
  >({
    component: ActionModal,
    data: {
      onSubmit: ({ data }) =>
        updateAbsenceRecord(data, {
          onSuccess: () =>
            postAction(
              "The Time Off request has been updated.",
              false,
              hideModal
            ),
          onError: () =>
            postAction(
              "Error updating the Time Off request. Please try again in a few seconds",
              true,
              hideModal
            ),
        }),
      testID: "manage-request",
    },
  });

  return {
    showActionModal,
  };
};
