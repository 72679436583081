import React from "react";
import { GridColDef } from "@mui/x-data-grid";
import { format, parseISO } from "date-fns";
import { ApprovalCell, ApprovalIcon } from "../../components";
import { Absence, ApprovalStatus } from "../../API";

type Actions = {
  editable: boolean;
  showActionModal: (
    params?:
      | {
          row: Absence;
          status: ApprovalStatus;
        }
      | undefined
  ) => void;
};

export const columns = ({
  editable,
  showActionModal,
}: Actions): GridColDef<Absence>[] => [
  {
    field: "approvalStatus",
    headerName: "Status",
    width: 72,
    align: "center",
    renderCell: ({ value }) => <ApprovalIcon status={value} />,
  },
  {
    field: "employee",
    headerName: "Employee",
    minWidth: 200,
    flex: 1,
    valueGetter: ({ row: { employee } }) =>
      `${employee.firstName} ${employee.lastName}`,
  },
  {
    field: "startDate",
    headerName: "Start",
    minWidth: 112,
    renderCell: ({ value }) => format(parseISO(value), "dd/MM/yyyy"),
  },
  {
    field: "endDate",
    headerName: "End",
    minWidth: 112,
    renderCell: ({ value }) => format(parseISO(value), "dd/MM/yyyy"),
  },
  {
    field: "amount",
    headerName: "Amount",
    type: "number",
  },
  {
    field: "description",
    headerName: "Description",
    minWidth: 200,
    editable: editable,
  },
  {
    field: "logIds",
    headerName: "Approval",
    type: "actions",
    minWidth: 200,
    renderCell: ({ row }) => (
      <ApprovalCell
        disable={{
          approve: row.approvalStatus === "approved",
          reject: row.approvalStatus === "rejected",
        }}
        onApprove={() =>
          showActionModal({ row, status: ApprovalStatus.approved })
        }
        onReject={() =>
          showActionModal({ row, status: ApprovalStatus.rejected })
        }
      />
    ),
  },
];

export const hiddenColumnsDesktop: { [key: string]: boolean } = {
  overtime: false,
};

export const hiddenColumnsMobile: { [key: string]: boolean } = {
  ...hiddenColumnsDesktop,
  sick: false,
  holiday: false,
};
