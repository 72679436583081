import { useQuery } from "@tanstack/react-query";
import { startOfYear, endOfYear } from "date-fns";
import { gqlQuery, toAWSDate } from "../../lib";
import { Absence } from "../../API";

export const useAbsenceQuery = () => {
  const currentDate = new Date();

  const { data, isLoading, isFetching, isPreviousData, error, ...rest } =
    useQuery(
      gqlQuery({
        req: "listAbsences",
        vars: {
          filter: {
            startDate: {
              between: [
                toAWSDate(startOfYear(currentDate)),
                toAWSDate(endOfYear(currentDate)),
              ],
            },
          },
        },
      })
    );

  const items = data?.data?.listAbsences?.items ?? [];

  return {
    items: items as Absence[],
    isPending: isLoading || isPreviousData || isFetching,
    error: error as Error,
    ...rest,
  };
};
