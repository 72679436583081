import { useQuery } from "@tanstack/react-query";
import { startOfYear, endOfYear } from "date-fns";
import { gqlQuery, toAWSDate } from "../../lib";
import { User, useUser } from "../../hooks";
import { Absence } from "../../API";
import { TimeOffEnum } from "../../lib/types/absences";

export const useTimeOffQuery = () => {
  const user = useUser() as User;
  const currentDate = new Date();
  const validTimeOff: string[] = [TimeOffEnum.Approved, TimeOffEnum.Pending];

  const { data, isLoading, isFetching, isPreviousData, error, ...rest } =
    useQuery(
      gqlQuery({
        req: "getEmployeeWithAbsences",
        vars: {
          id: user.id,
          startDate: [
            toAWSDate(startOfYear(currentDate)),
            toAWSDate(endOfYear(currentDate)),
          ],
        },
      })
    );

  const { vacationAllowance, absences } = data?.data?.getEmployee ?? {};
  const items = (absences?.items ?? []) as Absence[];
  const count = (acc: number, curr: Absence) =>
    acc +
    (validTimeOff.includes(curr?.approvalStatus ?? "") ? curr?.amount ?? 0 : 0);

  return {
    items,
    daysUsed: items.reduce(count, 0),
    isPending: isLoading || isPreviousData || isFetching,
    error: error as Error,
    vacationAllowance,
    ...rest,
  };
};
