/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCompany = /* GraphQL */ `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    name
    country
    inactiveAt
    clients {
      items {
        id
        firstName
        lastName
        email
        inactiveAt
        createdAt
        updatedAt
        companyClientsId
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCompanyQueryVariables,
  APITypes.GetCompanyQuery
>;
export const listCompanies = /* GraphQL */ `query ListCompanies(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      country
      inactiveAt
      clients {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCompaniesQueryVariables,
  APITypes.ListCompaniesQuery
>;
export const getClient = /* GraphQL */ `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    company {
      id
      name
      country
      inactiveAt
      clients {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    firstName
    lastName
    email
    inactiveAt
    employees {
      items {
        id
        clientID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    timelogs {
      items {
        id
        date
        standard
        overtime
        sick
        holiday
        notes
        approved
        onCall
        employeeID
        clientID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    companyClientsId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientQueryVariables, APITypes.GetClientQuery>;
export const listClients = /* GraphQL */ `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      company {
        id
        name
        country
        inactiveAt
        createdAt
        updatedAt
        __typename
      }
      firstName
      lastName
      email
      inactiveAt
      employees {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyClientsId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientsQueryVariables,
  APITypes.ListClientsQuery
>;
export const getEmployee = /* GraphQL */ `query GetEmployee($id: ID!) {
  getEmployee(id: $id) {
    id
    firstName
    lastName
    email
    country
    region
    vacationAllowance
    inactiveAt
    clients {
      items {
        id
        clientID
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    timelogs {
      items {
        id
        date
        standard
        overtime
        sick
        holiday
        notes
        approved
        onCall
        employeeID
        clientID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    absences {
      items {
        id
        startDate
        endDate
        amount
        description
        notes
        approvedByClient
        approvalStatus
        employeeID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmployeeQueryVariables,
  APITypes.GetEmployeeQuery
>;
export const listEmployees = /* GraphQL */ `query ListEmployees(
  $filter: ModelEmployeeFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmployeesQueryVariables,
  APITypes.ListEmployeesQuery
>;
export const getTimelog = /* GraphQL */ `query GetTimelog($id: ID!) {
  getTimelog(id: $id) {
    id
    date
    standard
    overtime
    sick
    holiday
    notes
    approved
    onCall
    employeeID
    clientID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTimelogQueryVariables,
  APITypes.GetTimelogQuery
>;
export const listTimelogs = /* GraphQL */ `query ListTimelogs(
  $filter: ModelTimelogFilterInput
  $limit: Int
  $nextToken: String
) {
  listTimelogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      date
      standard
      overtime
      sick
      holiday
      notes
      approved
      onCall
      employeeID
      clientID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTimelogsQueryVariables,
  APITypes.ListTimelogsQuery
>;
export const getAbsence = /* GraphQL */ `query GetAbsence($id: ID!) {
  getAbsence(id: $id) {
    id
    startDate
    endDate
    amount
    description
    notes
    approvedByClient
    approvalStatus
    employeeID
    employee {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAbsenceQueryVariables,
  APITypes.GetAbsenceQuery
>;
export const listAbsences = /* GraphQL */ `query ListAbsences(
  $filter: ModelAbsenceFilterInput
  $limit: Int
  $nextToken: String
) {
  listAbsences(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      startDate
      endDate
      amount
      description
      notes
      approvedByClient
      approvalStatus
      employeeID
      employee {
        id
        firstName
        lastName
        email
        country
        region
        vacationAllowance
        inactiveAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAbsencesQueryVariables,
  APITypes.ListAbsencesQuery
>;
export const getClientContracts = /* GraphQL */ `query GetClientContracts($id: ID!) {
  getClientContracts(id: $id) {
    id
    clientID
    employeeID
    client {
      id
      company {
        id
        name
        country
        inactiveAt
        createdAt
        updatedAt
        __typename
      }
      firstName
      lastName
      email
      inactiveAt
      employees {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyClientsId
      __typename
    }
    employee {
      id
      firstName
      lastName
      email
      country
      region
      vacationAllowance
      inactiveAt
      clients {
        nextToken
        __typename
      }
      timelogs {
        nextToken
        __typename
      }
      absences {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientContractsQueryVariables,
  APITypes.GetClientContractsQuery
>;
export const listClientContracts = /* GraphQL */ `query ListClientContracts(
  $filter: ModelClientContractsFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      clientID
      employeeID
      client {
        id
        firstName
        lastName
        email
        inactiveAt
        createdAt
        updatedAt
        companyClientsId
        __typename
      }
      employee {
        id
        firstName
        lastName
        email
        country
        region
        vacationAllowance
        inactiveAt
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientContractsQueryVariables,
  APITypes.ListClientContractsQuery
>;
