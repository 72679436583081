import {
  GetAbsenceQueryVariables,
  GetAbsenceQuery,
  ListAbsencesQueryVariables,
  ListAbsencesQuery,
  CreateAbsenceMutationVariables,
  CreateAbsenceMutation,
  UpdateAbsenceMutationVariables,
  UpdateAbsenceMutation,
  DeleteAbsenceMutationVariables,
  DeleteAbsenceMutation,
  ListAbsencesWithEmployeesQueryVariables,
  ListAbsencesWithEmployeesQuery,
  GetEmployeeWithAbsencesQuery,
  GetEmployeeWithAbsencesQueryVariables,
} from "../../API";

type Get = {
  req: "getAbsence";
  vars: GetAbsenceQueryVariables;
};

type List = {
  req: "listAbsences";
  vars?: ListAbsencesQueryVariables;
};

type GetEmployeeWithAbsences = {
  req: "getEmployeeWithAbsences";
  vars?: GetEmployeeWithAbsencesQueryVariables;
};

type ListAbsencesWithEmployees = {
  req: "listAbsencesWithEmployees";
  vars?: ListAbsencesWithEmployeesQueryVariables;
};

type Create = {
  req: "createAbsence";
};

type Update = {
  req: "updateAbsence";
};

type Delete = {
  req: "deleteAbsence";
};

export type AbsenceMutation = Create | Update | Delete;
export type AbsenceQuery =
  | List
  | GetEmployeeWithAbsences
  | ListAbsencesWithEmployees
  | Get;

export type AbsenceReq<T> = T extends Get
  ? GetAbsenceQuery
  : T extends List
  ? ListAbsencesQuery
  : T extends Create
  ? CreateAbsenceMutation
  : T extends Update
  ? UpdateAbsenceMutation
  : T extends Delete
  ? DeleteAbsenceMutation
  : T extends GetEmployeeWithAbsences
  ? GetEmployeeWithAbsencesQuery
  : T extends ListAbsencesWithEmployees
  ? ListAbsencesWithEmployeesQuery
  : never;

export type AbsenceVar<T> = T extends Create
  ? CreateAbsenceMutationVariables
  : T extends Update
  ? UpdateAbsenceMutationVariables
  : T extends Delete
  ? DeleteAbsenceMutationVariables
  : never;

export enum TimeOffEnum {
  Approved = "approved",
  Pending = "pending",
  Rejected = "rejected",
  Expired = "expired",
}
